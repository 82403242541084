<template>
  <div class="justify-center">
    <!-- <MainHeaderAlt /> -->

    <div class="mx-auto max-w-md">
      <header class="mb-10">
        <h1 class="text-xl mb-2 text-prasset-green-500">Nieuw project</h1>
        <p class="text-gray-600">Om te beginnen moet je het nieuwe project een naam geven.</p>
        <ValidationErrorMessage :errors="validationErrors" />
      </header>

      <form @submit.prevent="submit()">
        <label for="project_name" class="text-sm text-prasset-gray-800 mb-2 block">Vul een naam in voor je project</label>
        <div class="flex items-center">
          <text-input
            id="project_name"
            class="w-full"
            placeholder="Projectnaam"
            :required="true"
            name="name"
            v-model="project.name"
          />
          <button class="button button--opague ml-4" type="submit">
            <i class="icon-arrow-right" />
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/providers/api';
// import MainHeaderAlt from '@/modules/core/views/components/MainHeaderAlt';
import TextInput from '@/components/field/Text';
import ValidationErrorMessage from '@/modules/core/views/components/ValidationErrorMessage';

export default {
  components: {
    // MainHeaderAlt,
    TextInput,
    ValidationErrorMessage,
  },

  data() {
    return {
      validationErrors: [],
      project: {
        name: '',
      },
    };
  },

  methods: {
    submit() {
      this.validationErrors = [];
      Api().post('projects', this.project).then(({ data }) => {
        this.$router.push('/');
      }).catch(({ response }) => {
        const data = response.data;
        this.validationErrors = data.errors ? data.errors : { message: [data.message] };
      });
    },
  },
};
</script>
