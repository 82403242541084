<template>
  <header class="fixed flex justify-between items-center right-0 top-0 left-0" style="height: 60px;">
    <router-link :to="{ name: 'home' }" class="px-6">
      <svg width="20" height="32" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
        <g fill="none" fill-rule="evenodd">
          <path
            d="M4.049 31.773a.74.74 0 01-.734-.635L.008 8.15a.74.74 0 01.396-.765L14.723.08a.74.74 0 011.053.471l4.107 15.6a.74.74 0 01-1.012.868l-14.95-6.493a.74.74 0 01.59-1.359l13.55 5.885-3.484-13.235L1.549 8.464l2.807 19.508 4.51-12.607a.741.741 0 011.396.5L4.746 31.281a.741.741 0 01-.697.49"
            fill="#078080"
          />
        </g>
      </svg>
    </router-link>

    <MeMenu class="mt-4 mr-4" :showMenu="false" flyoutPosition="left-down" />
  </header>
</template>

<script>
import MeMenu from '@/modules/core/views/components/MeMenu';

export default {
  components: {
    MeMenu,
  },
};
</script>
