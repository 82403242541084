<template>
  <div>
    <MainHeaderAlt />

    <div class="py-20">
      <img src="@/assets/onboarding.svg" class="mx-auto w-auto mb-10" />
      <div class="mx-auto max-w-md">
        <header class="mb-10" v-if="loading">
          <h1 class="text-3xl text-prasset-gray-900 flex flex-row items-end leading-none mb-2">
            <span class="mr-1">Een moment geduld</span><LoadingIndicator class="mb-1"/>
          </h1>
          <p class="text-lg text-gray-600 mb-3">Het demo project word aangemaakt dit kan enkele minuten duren.</p>
        </header>
        <header class="mb-10" v-if="!loading">
          <h1 class="text-3xl text-prasset-gray-900 flex flex-row items-end leading-none mb-2">
            Klaar!
          </h1>
          <p class="text-lg text-gray-600 mb-3">Het demo projectis aangemaakt je kunt starten met prasset.</p>
        </header>
          <router-link
            to="/"
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading }"
            :disabled="loading"
          >
            <span>
              Starten met prasset
            </span>
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeaderAlt from '@/modules/core/views/components/MainHeaderAlt';
import LoadingIndicator from '@/components/LoadingIndicator';
import useAuth from '@/compositions/useAuth';
import Api from '@/providers/api';

export default {
  components: {
    MainHeaderAlt,
    LoadingIndicator,
  },

  setup() {
    const { fetchMe, loading } = useAuth();

    Api().post(`projects/demo`);

    const interval = setInterval(() => {
      Api().get(`projects/demo-check`)
        .then(() => {
          stopInterval();
        });
    }, 17500);


    const stopInterval = () => {
      clearInterval(interval);

      fetchMe();
    };

    return {
      loading,
    };
  },
};
</script>
